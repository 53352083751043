import React, { useEffect } from 'react';

const CookiePolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const name = "Mobi Med Ordinacija";

  return (
    <div className="container cookies-policy my-5">
      <h1 className="text-center mb-5">POLITIKA KOLAČIĆA (COOKIES POLICY)</h1>
      <h2>ŠTA SU TO KOLAČIĆI (COOKIES)</h2>
      <p>Kolačići su tekstualne datoteke koje veb-sajtovi mogu koristiti da bi pregledanje učinili efikasnijim za korisnika. Sadrže osnovne informacije o pretraživanju Interneta i zahvaljujući pretraživaču se prepoznaju svaki put kada korisnik poseti sajt. Kolačići koji su striktno neophodni za rad ovog sajta mogu se čuvati na vašem uređaju. Za sve druge vrste kolačića potrebna je vaša saglasnost.</p>
      <p>Ova politika kolačića ima za cilj da ilustruje vrste i metode korišćenja kolačića, kao i da pruži informacije o radnjama za izmenu, odbijanje ili onemogućavanje kolačića na ovom sajtu.</p>
      <p>Ako blokirate kolačiće, možda nećete moći da pristupite određenim delovima ovoga veb-sajta, a određene funkcije i stranice neće raditi na uobičajeni način.</p>
      <p>Za više informacija o kontrolorima vaših ličnih podataka, pogledajte odeljak Privacy Policy ovoga sajta.</p>
      <p>{name} se obavezuje da će poštovati odredbe sadržane u Kodeksu u vezi sa zaštitom ličnih podataka, izmenjenim Zakonodavnom uredbom od 28. maja 2012, br. 69, u transpoziciji Evropske direktive 2009/136 / EC.</p>
      <h2>KOJE KOLAČIĆ KORISTIMO</h2>
      <h3>Tehnički kolačići</h3>
      <p>To su kolačići koji omogućavaju korisniku da pregleda veb lokaciju i da pravilno koristi usluge koje se nude, kao što je, na primer, identifikacija sesije pregledanja, pristup njihovom korisničkom panelu, pravilno upravljanje procedurama kreiranja naloga i plaćanja. kupovina u prodavnici, ako postoje. Takođe se koriste za praćenje podešavanja kolačića koje je korisnik izabrao.</p>
      <p>Ove kolačiće postavlja server ovog sajta i neophodni su za pravilno funkcionisanje sajta u skladu sa zakonskim odredbama na snazi. Stoga se ne mogu i ne smeju onesposobiti.</p>

      <div className="d-block d-md-none">
        <p className="fw-bold">Kolačić: cookieDisclaimerId</p>
        <ul>
          <li><b>Kada se aktivira: </b>Kada se potvrdi banner sa kolačićima</li>
          <li><b>Trajanje: </b>1 godinu</li>
          <li><b>Vrednost: </b>Jedinstveni identifikator potvrde kolačića koji je izrazio korisnik sa pretraživačem</li>
          <li><b>Čemu služi: </b>Omogućava održavanje reference na strani servera o informacijama i opcijama kolačića koje je korisnik prihvatio/odbio</li>
        </ul>
        <p className="fw-bold">Kolačić: cookieDisclaimer</p>
        <ul>
          <li><b>Kada se aktivira: </b>Kada se potvrdi banner sa kolačićima</li>
          <li><b>Trajanje: </b>1 godinu</li>
          <li><b>Vrednost: </b>Tekstualni niz koji sadrži izabrane opcije kolačića</li>
          <li><b>Čemu služi: </b>Sadrži listu izabranih kolačića</li>
        </ul>
        <p className="fw-bold">Kolačić: cookieDisclaimerValues</p>
        <ul>
          <li><b>Kada se aktivira: </b>Kada se potvrdi banner sa kolačićima</li>
          <li><b>Trajanje: </b>1 godinu</li>
          <li><b>Vrednost: </b>Tekstualni niz koji sadrži moguće opcije kolačića</li>
          <li><b>Čemu služi: </b>Sadrži listu svih kolačića koje korisnik može izabrati</li>
        </ul>
        <p className="fw-bold">Kolačić: cookieDisclaimerExpires</p>
        <ul>
          <li><b>Kada se aktivira: </b>Kada se potvrdi banner sa kolačićima</li>
          <li><b>Trajanje: </b>1 godinu</li>
          <li><b>Vrednost: </b>Vrednost trajanja izračunato kao današnji datum + 365 u formatu UTC</li>
          <li><b>Čemu služi: </b>Sadrži vreme trajanja izabranih vrednosti kolačića</li>
        </ul>
        <p className="fw-bold">Kolačić: cart</p>
        <ul>
          <li><b>Kada se aktivira: </b>U prodavnici (ako postoji), kada se korpa za kupovinu ažurira</li>
          <li><b>Trajanje: </b>sesija (session)</li>
          <li><b>Vrednost: </b>&quot;&lt;productid&gt;:&lt;quantity&gt;[#&lt;option-name&gt;:&lt;option-value&gt;]...&quot;</li>
          <li><b>Čemu služi: </b>Sadrži listu svih proizvoda u korpi sa njihovim količinama i odabranim opcijama</li>
        </ul>
        <p className="fw-bold">Kolačić: order</p>
        <ul>
          <li><b>Kada se aktivira: </b>U prodavnici (ako postoji), kada idete na plaćanje</li>
          <li><b>Trajanje: </b>sesija (session)</li>
          <li><b>Vrednost: </b>Jedinstveni identifikator narudžbenice</li>
          <li><b>Čemu služi: </b>Prati broj porudžbine koji je dodeljen korpi</li>
        </ul>
      </div>

      <div className="table-responsive d-none d-md-block">
        <table className="table">
          <thead>
            <tr>
              <th>Kolačić</th>
              <th>Kada se aktivira</th>
              <th>Trajanje</th>
              <th>Vrednost</th>
              <th>Čemu služi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cookieDisclaimerId</td>
              <td>Kada se potvrdi banner sa kolačićima</td>
              <td>1 godinu</td>
              <td>Jedinstveni identifikator potvrde kolačića koji je izrazio korisnik sa pretraživačem</td>
              <td>Omogućava održavanje reference na strani servera o informacijama i opcijama kolačića koje je korisnik prihvatio/odbio</td>
            </tr>
            <tr>
              <td>cookieDisclaimer</td>
              <td>Kada se potvrdi banner sa kolačićima</td>
              <td>1 godinu</td>
              <td>Tekstualni niz koji sadrži izabrane opcije kolačića</td>
              <td>Sadrži listu izabranih kolačića</td>
            </tr>
            <tr>
              <td>cookieDisclaimerValues</td>
              <td>Kada se potvrdi banner sa kolačićima</td>
              <td>1 godinu</td>
              <td>Tekstualni niz koji sadrži moguće opcije kolačića</td>
              <td>Sadrži listu svih kolačića koje korisnik može izabrati</td>
            </tr>
            <tr>
              <td>cookieDisclaimerExpires</td>
              <td>Kada se potvrdi banner sa kolačićima</td>
              <td>1 godinu</td>
              <td>Vrednost trajanja izračunato kao današnji datum + 365 u formatu UTC</td>
              <td>Sadrži vreme trajanja izabranih vrednosti kolačića</td>
            </tr>
            <tr>
              <td>cart</td>
              <td>U prodavnici (ako postoji), kada se korpa za kupovinu ažurira</td>
              <td>sesija (session)</td>
              <td>tekstualni niz tipa: &lt;productid&gt;:&lt;quantity&gt;[#&lt;option-name&gt;:&lt;option-value&gt;]...</td>
              <td>Sadrži listu svih proizvoda u korpi sa njihovim količinama i odabranim opcijama</td>
            </tr>
            <tr>
              <td>order</td>
              <td>U prodavnici (ako postoji), kada idete na plaćanje</td>
              <td>sesija (session)</td>
              <td>Jedinstveni identifikator narudžbenice</td>
              <td>Prati broj porudžbine koji je dodeljen korpi</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>Tehnički kolačići trećih strana</h3>
      <p><b>Google Analytics</b><br />Google analitika je besplatna usluga veb analitike koju pruža Google i koja vam omogućava da analizirate statistiku pristupa posetiocima veb sajta, u anonimnom i zbirnom obliku. Ovaj sajt je aktivirao uslugu anonimizacije svih podataka koje prikuplja Google analitika i ne deli i/ili kombinuje podatke sa drugim uslugama i/ili aplikacijama. Kolačići Google analitike stoga mogu, u konkretnom slučaju korišćenja na ovom sajtu, biti uključeni u kategoriju tehničkih.<br />Informacije o servisu - Politika privatnosti - Politika kolačića - Obrazac saglasnosti - Opt Out</p>
      <h3>Marketing kolačići - profilisanje u svrhe oglašavanja i remarketinga trećih strana</h3>
      <p>Ovaj sajt sadrži servise trećih strana koje zauzvrat mogu da instaliraju kolačiće za pravilno funkcionisanje usluga koje pružaju. Evo linkova za pristup stranicama različitih servisa na kojima je objašnjeno kako se kolačići koriste i kako ih omogućiti/onemogućiti. Ovi kolačići (usluge oglašavanja trećih strana) se koriste da bi se prikazalo personalizovano oglašavanje i sadržaj na osnovu istorije pregledanja korisnika.</p>
      <h2>UPUTSTVO ZA ONEMOGUĆIVANJE KOLAČIĆA IZ PRETRAŽAČA (BROWSER)</h2>
      <p>Ispod su linkovi za upravljanje kolačićima u najpopularnijim pretraživačima (po abecednom redu):</p>
      <a href="https://support.google.com/chrome/answer/95647?hl=sr">Chrome (Google)</a><br />
      <a href="https://support.microsoft.com/en-us/microsoft-edge">Edge (Microsoft)</a><br />
      <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox (Mozilla Foundation)</a><br />
      <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera (Opera Software)</a><br />
      <a href="https://support.apple.com/kb/PH21411?locale=en_US">Safari (Apple) - Desktop</a><br />
      <a href="https://support.apple.com/en-us/HT201265">Safari (Apple) - Mobile</a><br /><br />
      <p>Ako koristite drugi pretraživač, potražite kako da upravljate kolačićima u podešavanjima pretraživača.</p>
      <p>Možete da promenite podešavanja kolačića ove stranice klikom na ovaj link.</p>
      <p>Imajte na umu da onemogućavanjem kolačića neke funkcije sajta možda neće raditi ispravno ili uopšte neće raditi. Minimalna količina informacija je u svakom slučaju uskladištena u vašem pretraživaču (preko tehničkih kolačića) čak i kada su svi kolačići onemogućeni, kako bi se omogućilo sistemu da zapamti podešavanja kolačića koje ste izabrali.</p>
      <p>Klikom na PRIHVATI na baneru koji se nalazi pri prvom pristupu sajtu, izričito se slažete sa upotrebom kolačića, posebno sa skladištenjem ovih kolačića na vašem uređaju u gore navedene svrhe.</p>
    </div>
  );
};

export default CookiePolicy;
