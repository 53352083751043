import React from "react";
import LandingServices from "../components/LandingServices";

function LandingPage() {
  return (
    <>
      <LandingServices />
    </>
  );
}

export default LandingPage;
