import React from "react";
import VideoComponent from "../components/VideoPage";
import VideoHeading from "../components/VIdeoHeading";
function Video() {
  return (
    <>
      <VideoHeading />
      <VideoComponent />
    </>
  );
}

export default Video;
