import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Logo from '../img/MobiMed-Logo-1-.png';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation(); // Dohvata trenutni URL

  // Proverava da li je trenutna stranica detaljna stranica (DetailPage)
  const isDetailPage = location.pathname.startsWith("/detail");
    return (
        <footer  className={isDetailPage ? "fixed-footer" : "footer"} style={{ backgroundColor: '#383838', color: '#ffffff' }}>
            <div className=" p-5 pt-0 pb-3">
                <div className="row fotcont">
                    <div className="col-md-6 ">
                        <div className="footer-info pt-3">
                            <p className='mb-1'><FontAwesomeIcon icon={faMapMarkerAlt} /> Vojvode Radomira Putnika 18, sp. I, st. 2, Pančevo</p>
                            <p className='mb-1'><FontAwesomeIcon icon={faPhone} /> 062-418-561 / 013-342-180</p>
                            <p className='mb-1'><FontAwesomeIcon icon={faEnvelope} /> mobimed.ord@gmail.com</p>
                        </div>
                    </div>
                    <div className="col-md-6  d-flex align-items-center justify-content-center justify-content-md-end">
                        <img src={Logo} alt="Logo" style={{ maxHeight: '70px' }} />
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="/privacy-policy" className="mx-3 ftxt">Politika privatnosti</Link>
                    <Link to="/cookie-policy" className="mx-3 ftxt">Kolačići</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
