import React, { useEffect, useState } from "react";
import "../App.css";
import logo from "../img/MobiMed-Logo-1-.png";
import icon from "../img/output-onlinepngtools-4-.png";
import { HashLink as NavLink } from "react-router-hash-link";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const [hideDiv, setHideDiv] = useState(false);

  useEffect(() => {
    // Dodavanje event listener-a za scroll
    const handleScroll = () => {
      if (window.scrollY > 80) {
        // Postavite odgovarajuću vrednost za scrollY
        setHideDiv(true); // Sakrijemo div
      } else {
        setHideDiv(false); // Prikažemo div ako nije skrolovan dole
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Čišćenje event listener-a prilikom unmount-a komponente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Pratimo samo montažu komponente

  return (
    <>
      <div className="top">
        <div className={`  ${hideDiv ? "hide-strucnost" : "strucnost"}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-6">
                <p>Stručnost, lečenje i nega vrhunskog nivoa</p>
              </div>
              <div className="col-md-6 col-6 d-flex align-items-center justify-content-end">
                <p>
                  <img src={icon} alt="Kontakt ikonica" className="icon mr-2" />
                  Kontaktirajte nas +38113342180
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <NavLink className="navbar-brand" to="/#Home">
              <img src={logo} alt="Logo" width="auto" height="69" />
            </NavLink>
            <a
              className="text-decoration-none d-block d-lg-none col me-3 text-end"
              href="tel:+38162418561"
            >
              <img
                src="img/icon-phone.png"
                alt="phone"
                className="icon-header"
              />
            </a>
            <div className="ml-auto d-lg-none">
              {" "}
              {/* Samo za male ekrane */}
              <button
                className="btn-menu-mobile custom-toggler navbar-toggler"
                type="button"
                onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className={`collapse navbar-collapse justify-content-end ${
                isOpen ? "show" : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#Home"
                    onClick={closeMenu}
                  >
                    HOME
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#About"
                    onClick={closeMenu}
                  >
                    O NAMA
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#Services"
                    onClick={closeMenu}
                  >
                    USLUGE
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#Team"
                    onClick={closeMenu}
                  >
                    NAŠ TIM
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#Prices"
                    onClick={closeMenu}
                  >
                    CENOVNIK
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/Video"
                    onClick={closeMenu}
                  >
                    VIDEO MATERIJALI
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/Blog"
                    onClick={closeMenu}
                  >
                    BLOG
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    smooth
                    to="/#Contact"
                    onClick={closeMenu}
                  >
                    KONTAKT
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
