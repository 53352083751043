
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import validateForm from "../components/validation";

const Contact = () => {



    const formRef = useRef();
    const [formData, setFormData] = useState({
        message: "",
        to: "mobimed.ord@gmail.com"
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitError] = useState(false); // Stanje za prikaz poruke o grešci



    const sendContactMessage = (event) => {
        if (!validateForm(formRef.current)) return;
        const _formData = new FormData();
        for (var i in formData) {
            _formData.append(i, formData[i]);
        }
        fetch('https://appsterdev.cubenet.rs/mail_cv.php', { method: 'POST', body: _formData }).then(res => res.text());
        setFormSubmitted(true);
    };

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    return (

        <div className="row equal-height g-0">
            {/* Left part: Contact Info */}
            <div className="col-md-4 bg-primary1 text-white d-flex flex-column p-4 p-lg-5">
                <h3 className="text-center mb-4 tim1">Kontakt</h3>
                <p className="dani mb-4">
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> <b>Adresa:</b><br /> Vojvode Radomira Putnika 18, sp. I, st. 2, Pančevo
                </p>
                <p className="dani mb-4">
                    <FontAwesomeIcon icon={faPhone} /> <b>Telefon:</b><br /> 062-418-561 <br /> 013-342-180
                </p>
                <p className="dani mb-4">
                    <FontAwesomeIcon icon={faEnvelope} /> <b>E-mail:</b><br /> mobimed.ord@gmail.com
                </p>
            </div>

            {/* Middle part: Working Hours */}
            <div className="col-md-4 bg-white d-flex flex-column p-4 p-lg-5">
                <h3 className="text-center mb-4 tim11">Radno Vreme</h3>
                <div className="working-hours w-100">
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Ponedeljak:</span>
                        <span className="dani">10:00 - 20:00</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Utorak:</span>
                        <span className="dani">10:00 - 20:00</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Sreda:</span>
                        <span className="dani">10:00 - 20:00</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Četvrtak:</span>
                        <span className="dani">10:00 - 20:00</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Petak:</span>
                        <span className="dani">10:00 - 20:00</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Subota:</span>
                        <span className="dani">Po potrebi</span>
                    </div>
                    <div className="d-flex justify-content-between w-100 linija">
                        <span className="dani">Nedelja:</span>
                        <span className="dani">Po potrebi</span>
                    </div>
                </div>
            </div>

            {/* Right part: Contact Form */}
            <div className="col-md-4 bg-primary1 text-white d-flex flex-column p-4 p-lg-5">
                <h3 className="text-center mb-4 tim1">Pišite nam!</h3>
                {!formSubmitted && !submitError && (
                    <form onSubmit={sendContactMessage} action="#" name="contact-form" ref={formRef} className="w-100">
                        <div className="form-group">
                            <label htmlFor="formName">Ime</label>
                            <input type="text" id="name" name="name" className="form-control" defaultValue="" placeholder="" required onChange={changeHandler} />
                            {/* <input type="text" className="form-control" id="name" placeholder="" /> */}
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="formLastName">Prezime</label>
                            <input type="text" id="name" name="name" className="form-control" defaultValue="" placeholder="" required onChange={changeHandler}/>
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="formEmail">Email</label>
                            <input type="email" id="emailid" name="email" className="form-control" defaultValue="" placeholder="" required onChange={changeHandler} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="formPhone">Telefon</label>
                            <input type="text" id="phone" name="phoneNumber" className="form-control" defaultValue="" placeholder="" required onChange={changeHandler} />

                        </div>
                        <div className="form-group">
                            <label htmlFor="formMessage">Poruka</label>
                            <textarea
                                name="message"
                                id="message"
                                className="form-control"
                                rows={3}
                                placeholder=""
                                required
                                onChange={changeHandler}
                            />
                        </div>
                        <button type="submit" className="col-4 btn btn-light mt-4">Pošalji</button>
                    </form>
                )}
                {formSubmitted && ( // Prikazi poruku nakon uspešnog slanja
                    <h5 className="w-100 my-5 text-white text-center">Hvala vam što ste nas kontaktirali!</h5>
                )}
                {submitError && ( // Prikazi poruku o grešci ako se desi greška pri slanju
                    <h5 className="w-100 my-5 text-white text-center">Došlo je do greške prilikom slanja poruke. Molimo vas, pokušajte ponovo kasnije.</h5>
                )}
            </div>
        </div>

    );
};

export default Contact;
