import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="back-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="bg-transparent border-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" fill="white" />
            <path fill="#06a89e" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.15 2.15a.5.5 0 1 0 .7-.7l-3-3a.5.5 0 0 0-.7 0l-3 3a.5.5 0 1 0 .7.7l2.15-2.15V11.5A.5.5 0 0 0 8 12z" /> {/* Boja strelice */}
          </svg>
        </button>
      )}
    </div>
  );
};

export default BackToTopButton;