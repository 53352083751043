import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import data from "../data/blog.json";
import { Link } from "react-router-dom";

const BlogCard = () => {
  return (
    <div className="blog-card-back">
      <div className="container mt-5">
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-md-6" key={item.id} style={{ display: "flex", flexDirection: "column" }}>
              <Link to={`/detail/${item.id}`} className="text-decoration-none" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div className="card1 card shadow-sm m-md-5 m-3" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                  <img
                    src={item.image}
                    className="card-img-top1"
                    alt={item.title}
                  />
                  <div className="card-body" style={{ flexGrow: 1 }}>
                    <h5 className="card-title1">{item.title}</h5>
                    {/* <p className="card-text1">{item.small}</p> */}
                    <div className='card-text1' dangerouslySetInnerHTML={{ __html: item.small }} />
                    <div className="text-muted small text-start">
                      {new Date(item.date).toLocaleDateString("sr-RS")}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
