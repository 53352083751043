import React, { useEffect } from 'react';

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const name = "Mobi Med Ordinacija";
  const address = "Vojvode Radomira Putnika br. 18, sp. 1, st. 2, 26000 Pančevo";
  const email = "mobimed.ord@gmail.com";
  const pib = "112963757";

  return (
    <div className="container privacy-policy py-5">
      <h1 className="text-center mb-5 fw-bold">POLITIKA PRIVATNOSTI</h1>
      <p>U skladu sa čl. 13 Uredbe EU 679/2016 („GDPR“) kao i sa Zakonom o zaštiti podataka o ličnosti republike Srbije (Službeni glasnik 87/2018-54), Kodeksom i GDPR-om, {name}, kao što je definisano u nastavku, kao „kontrolor podataka“, ovom prilikom objašnjava načine korišćenja ličnih podataka koje pružaju korisnici koji pregledaju i/ili imaju bilo kakvu interakciju sa sajtom.</p>
      <h2 className="fw-bold">Svrha obrade podataka</h2>
      <p>Korisnik je slobodan da da svoje lične podatke za slanje svoje biografije, da zatraži slanje informativnog materijala ili druge komunikacije. Obrada ličnih podataka može se vršiti samo radi odgovora na primljene zahteve. Pružanje takvih podataka je opciono, ali ako ih ne pružite, može biti nemoguće dobiti ono što se traži.</p>
      <h2 className="fw-bold">Vrsta obrađenih podataka</h2>
      <p>Da bismo pratili zahteve primljene preko veb stranice, obradićemo kontakt podatke koje je zainteresovana strana unela u odgovarajući obrazac. Vaši identifikacioni podaci prikupljeni u trenutku registracije na sajtu će takođe biti obrađeni.</p>
      <h2 className="fw-bold">Metode obrade podataka</h2>
      <p>Obrada ličnih podataka se odvija korišćenjem ručnih, IT i telematskih alata i na način da se garantuje sigurnost i poverljivost podataka. Sistemi prevencije i zaštite se koriste, stalno se ažuriraju i proveravaju u pogledu pouzdanosti.</p>
      <h2 className="fw-bold">Treća lica koja mogu imati pristup podacima</h2>
      <p>Da bi ostvarili gore navedene svrhe, {name} treba da prosledi lične podatke svojim zaposlenima koji su u tu svrhu "imenovani" kao i „odgovornim“ licima trećih strana. Kompletna lista zaposlenih dostupna je u sedištu vlasnika. Posebno je precizirano da takvi podaci neće biti otkriveni i da će ih obrađivati odgovorna lica koji su propisno imenovani za obavljanje ovih zadataka, odgovarajuće obučeni i upoznati sa ograničenjima nametnutim zakonom, kao i putem korišćenje bezbednosnih mera za garantovanje zaštite vaše privatnosti i izbegavanje rizika od gubitka ili uništenja, neovlašćenog pristupa, obrade koja nije dozvoljena ili nije u skladu sa gore navedenim svrhama.</p>
      <h2 className="fw-bold">Trajanje čuvanja i obrade ličnih podataka</h2>
      <p>Vaši lični podaci će biti obrađeni:</p>
      <ul>
        <li>u vezi sa ličnim podacima, podacima o kontaktu i plaćanju: za vremenski period koji je potreban za ispunjenje kupoprodajnog ugovora za proizvod/uslugu i za vremenski period koji je propisan važećim zakonodavstvom (posebno deset godina za vreme čuvanja dokumentacije ugovorne i fiskalne) kao i do iscrpljivanja ugovornih obaveza između Nosilaca.</li>
        <li>na period od 12 (dvanaest meseci) u vezi sa ponašanjem pri pregledanju na Sajtu i drugim Internet sajtovima, vašim potrošačkim navikama, geografskom području i uređaju koji povezuje sajt, IP adresom;</li>
        <li>u trajanju od 24 (dvadesetčetiri) meseca potrebno za obavljanje marketinških aktivnosti.</li>
      </ul>
      <p>U navedenim slučajevima, zainteresovana strana ima mogućnost da se usprotivi obradi u bilo kom trenutku, opozvavši datu saglasnost. Treba napomenuti da se podaci ne koriste u svrhu usvajanja automatizovanih procesa donošenja odluka.</p>
      <h2 className="fw-bold">Odgovornost za obradu podataka</h2>
      <p>Odgovornost za obradu podataka snosi {name}, sa sedištem u {address}, {pib}, {email}</p>
      <h2>Prava zainteresovanih lica</h2>
      <p>Posetilac ima pravo da dobije:</p>
      <ul>
        <li>potvrdu o postojanju ili ne ličnih podataka koji se tiču vas, čak i ako još nisu registrovani;</li>
        <li>kopiju vaših ličnih podataka;</li>
        <li>brisanje vaših ličnih podataka;</li>
        <li>ograničenje obrade vaših ličnih podataka;</li>
        <li>u strukturiranom formatu, koji se obično koristi i čitljiv automatskim uređajem, lične podatke koje ste nam dali ili koje ste sami kreirali - isključujući izvedene informacije koje je napravio Rukovalac podataka i/ili odgovorna lica od strane lica ovlašćenih za obradu podataka u ime i za račun Rukovaoca podataka i da ih prenese, direktno ili preko Rukovaoca podataka, drugom kontroloru podataka;</li>
      </ul>
      <p>Informacije o:</p>
      <ul>
        <li>poreklu ličnih podataka;</li>
        <li>kategorije ličnih podataka koji se obrađuju;</li>
        <li>svrhe i metode obrade;</li>
        <li>logike primenjene u slučaju tretmana koji se sprovodi uz pomoć elektronskih instrumenata;</li>
        <li>identitet vlasnika i svih menadžera;</li>
        <li>period čuvanja vaših ličnih podataka ili kriterijume koji su korisni za određivanje ovog perioda;</li>
        <li>subjekata ili kategorija subjekata kojima se lični podaci mogu saopštiti ili koji o njima mogu saznati kao imenovani predstavnici u državi, rukovodioci ili lica ovlašćena za obradu podataka u ime i za račun Rukovaoca podataka</li>
      </ul>
      <ul>
        <li>ažuriranje, ispravljanje ili kada ste zainteresovani, integracija podataka;</li>
        <li>pretvaranje u anonimnu formu ili blokiranje podataka koji se obrađuju suprotno zakonu, uključujući i one koji se ne moraju čuvati u svrhe za koje su podaci prikupljeni ili naknadno obrađeni;</li>
      </ul>
      <p>Imate pravo da prigovorite, u celini ili delimično:</p>
      <ul>
        <li>Iz legitimnih razloga, na obradu ličnih podataka koji se tiču vas, čak i ako su relevantni za svrhu prikupljanja;</li>
        <li>na obradu ličnih podataka koji se odnose na vas u svrhu slanja reklamnog materijala ili materijala za direktnu prodaju ili za sprovođenje istraživanja tržišta ili komercijalne komunikacije.</li>
      </ul>
      <p>Za ostvarivanje navedenih prava možete poslati poruku NA SLEDEĆU ADRESU:</p>
      <p>{name} - {address}.</p>
      <p>navodeći kao naslov „Privatnost – ostvarivanje prava u skladu sa Zakonom o zaštiti ličnih podataka“.</p>
      <p>Na kraju, obaveštavamo Vas da ukoliko smatrate da su Vaša prava povređena od strane Rukovaoca podataka i/ili trećeg lica, imate pravo da uložite žalbu Povereniku za zaštitu ličnih podataka i/ili drugom nadležnom nadzornom organu Republike Srbije.</p>
    </div>
  );
};

export default PrivacyPolicy;
