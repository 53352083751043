import React from 'react';
import Slider from 'react-slick';
import doctorData from '../data/doctorData.json';
import '../App.css';
import { Autocomplete } from '@react-google-maps/api';

const DoctorSlider = () => {
  const settings = {    
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="doctor-slider-container">
      <div className="container pt-5 pb-md-5">
        <h1 className='tim'>STRUČNI TIM</h1>
        <Slider {...settings}>
          {doctorData.map((doctor, index) => (
            <div key={index} className="slide-item">
              <div className="row p-3">
                <div className="col-6 doctor-info align-content-center d-none d-lg-block">
                  <h5 className="card-title1"><b>{doctor.name}</b></h5>
                  <p className="card-text1">{doctor.position}</p>
                </div>
                <div className="col-lg-6">
                  <img src={doctor.image} alt={doctor.name} className="doctor-image" />
                </div>
                <div className="col-lg-6 doctor-info pt-3 d-block d-lg-none">
                  <h5 className="card-title1"><b>{doctor.name}</b></h5>
                  <p className="card-text1">{doctor.position}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default DoctorSlider;
