import React from 'react';
import BlogHeading from '../components/BlogHeading';
import BlogText from '../components/BlogText';
import BlogCard from '../components/BlogCard';
function Blog() {

    return (
        <>
           <BlogHeading />
           <BlogText />
           <BlogCard />
        </>
    );
}

export default Blog;
