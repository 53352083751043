import React from "react";
import data from "../data/video.json";

const VideoTextComponent = () => {
  const videoData = data[0];
  return (
    <div style={{ backgroundColor: "white", padding: "20px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 p-0 d-flex justify-content-center align-items-right">
            <video
              src={videoData.video}
              alt="Video"
              className=""
              style={{ width: "360px", height: "auto" }}
              controls
            />
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div>
              <h2
                className="blog-text1"
                style={{ color: "#06a89e", fontSize: "25px" }}
              >
                {videoData.title}
              </h2>
              <p>{videoData.text}</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <a
                href="/Video"
                className="btn video-btn"
                style={{
                  fontSize: "18px",
                }}
              >
                Pogledaj sve video materijale
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTextComponent;
