import React, { useState, useEffect } from "react";
import data from "../data/slajderusluge.json";

const LandingServices = () => {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    setSliderData(data);
  }, []);

  return (
    <div className="landing-back pt-5 pb-5">
      <div className="container">
        <h3 className="text-center tim">USLUGE</h3>
        <div className="row gy-4">
          {sliderData.map((item, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-item position-relative h-100">
                <div className="icon">
                  <i
                    className={`fas ${item.icon}`}
                    style={{ fill: "#06a89e" }}
                  ></i>
                </div>
                <a href="/" className="stretched-link">
                  <h3>{item.title}</h3>
                </a>
                <ul className="p-3 pt-0 lista">
                  {item.items.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandingServices;
