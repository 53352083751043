import React from "react";
import slika from "../img/blog-background.jpg";

const VideoHeading = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "450px" }}>
      <img
        src={slika}
        alt="Blog"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: "brightness(0.5)",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(6, 168, 158, 0.6)",
          zIndex: "1",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#fff",
          fontSize: "3rem",
          fontWeight: "bold",
          zIndex: "2",
        }}
      >
        <h1 className="blog-text1">Video Materijali</h1>
      </div>
    </div>
  );
};

export default VideoHeading;
