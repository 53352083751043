import React from 'react';

const MapComponent = () => {


    return (
        <section className="contact-page-map wow fadeInUp delay-0-2s mb-0">
            <div>
                <iframe src="https://maps.google.com/maps?q=44.87019,20.64671&z=15&output=embed&cid=8704477422486039734" width="100%" height="400px" frameborder="0" className='align-bottom' title="  "></iframe>
            </div>
        </section>
    );
};

export default MapComponent;
